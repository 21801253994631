<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <GoZayaanBrandLogo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- Left Text -->
      <!-- Login -->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Go Zayaan Admin! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="signIn"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <p class="text-danger">
                {{ message }}
              </p>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
              >
                <b-spinner
                  v-if="busy"
                  small
                />
                Sign In
              </b-button>
            </b-form>
          </validation-observer>
          <hr>
          <!-- <div class="social-btn social-btn-fb">
            <img src="@/assets/images/icons/icon-fb.svg">
            <span>Login with Facebook</span>
            <facebook-login
              class="button"
              app-id="936682426725239"
              @login="onLogin"
              @sdk-loaded="sdkLoaded"
            />
          </div> -->
          <div class="social-btn social-btn-g">
            <img src="@/assets/images/icons/icon-google.svg">
            <span>Login with Google</span>
            <GoogleLogin
              :params="params"
              :render-params="renderParams"
              :on-success="onSuccess"
            >Google</GoogleLogin>
          </div>
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require,no-undef */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import axios from 'axios'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import facebookLogin from 'facebook-login-vuejs'
import GoogleLogin from 'vue-google-login'
// eslint-disable-next-line no-unused-vars
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GoZayaanBrandLogo from '@/components/icons/GoZayaanBrandLogo.vue'
import account from '@/mixins/account'
import store from '@/store'
import api from '@/libs/api'

export default {
  components: {
    // VuexyLogo,
    // facebookLogin,
    GoogleLogin,
    ValidationProvider,
    ValidationObserver,
    GoZayaanBrandLogo,
  },
  mixins: [togglePasswordVisibility, account],
  data() {
    return {
      userType: 'AD',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      busy: false,
      message: '',
      params: {
        client_id: '573631129203-t9d66mm5hjlf5sv8goiqkg0d54664bd8.apps.googleusercontent.com',
      },
      facebook: {
        FB: {},
        model: {},
        appId: '936682426725239',
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    signIn() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.busy = true
          this.message = ''
          // api.postData('auth/login/', false, { // TODO: check if 400 handling is possible from the api method
          //   username: (this.userEmail).toLowerCase(),
          //   password: this.password,
          //   login_type: 'AD',
          // }).then(response => {
          //   if (response && response.status === 200) {
          //     this.$store.commit('auth/updateToken', `Token ${response.data.token}`)
          //     this.$store.commit('auth/updateExpiry', response.data.expiry)
          //     this.$store.commit('auth/updatePermissions', response.data.user.groups)
          //     this.$store.commit('auth/updateAccountInfo', response.data.user.account_info)
          //     this.getPermissionList()
          //     this.$router.replace('/redirect').then(() => {
          //       this.$toast({
          //         component: ToastificationContent,
          //         position: 'top-right',
          //         props: {
          //           title: 'Welcome to Go Zayaan Admin',
          //           icon: 'CoffeeIcon',
          //           variant: 'success',
          //           text: 'You have successfully logged in. Now you can start to explore!',
          //         },
          //       })
          //     })
          //   }
          // }).catch(error => {
          //   (error)
          //   // if (error.response.status === 400) {
          //   //   this.message = 'Email or password is incorrect.'
          //   // } else {
          //   //   this.message = 'Something went wrong, please try again later.'
          //   // }
          // }).finally(() => {
          //   this.busy = false
          // })
          axios({
            url: `${process.env.VUE_APP_BASE_URL}auth/login/`,
            method: 'post',
            data: {
              username: (this.userEmail).toLowerCase(),
              password: this.password,
              login_type: 'AD',
            },
          }).then(response => {
            if (response.status === 200) {
              this.setUserData(response.data)
            }
          }).catch(error => {
            if (error.response.status === 400) {
              this.message = 'Email or password is incorrect.'
            } else {
              this.message = 'Something went wrong, please try again later.'
            }
          }).finally(() => {
            this.busy = false
          })
        }
      })
    },
    getUserData() {
      const context = this
      // eslint-disable-next-line prefer-arrow-callback
      FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          FB.api('/me', { fields: 'id, name, email' }, res => {
            context.$store.commit('common/updateSocialName', res.name)
            context.$store.commit('common/updateTemporarySocialToken', response.authResponse.accessToken)
            api.postData('social_auth/facebook/', false, {
              token: response.authResponse.accessToken,
              device_type: 'WEB',
            }).then(responseData => {
              if (responseData.data.status) {
                if (responseData.data.result.phone === null) {
                  context.$store.commit('common/updateChosenSocialAccount', 'facebook')
                  context.$router.push({ path: '/phone-number' })
                } else {
                  context.setUserData(responseData.data.result)
                }
              }
            }).catch()
          })
        }
      })
    },
    // sdkLoaded(payload) {
    //   this.facebook.model.FB = payload.FB
    // },
    // onLogin() {
    //   this.getUserData()
    // },
    onSuccess(googleUser) {
      // const that = this
      const profile = googleUser.getBasicProfile()
      const idToken = googleUser.getAuthResponse().id_token
      this.$store.commit('common/updateTemporarySocialToken', idToken)
      api.postData('social_auth/google/', false, {
        token: idToken,
        device_type: 'WEB',
      }).then(res => {
        if (res.data.status) {
          this.$store.commit('common/updateSocialName', profile.getName())
          this.$store.commit('common/updateChosenSocialAccount', 'google')
          this.setUserData(res.data.result)
        }
      }).catch()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.social-btn {
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  padding: 10px 5px;
  text-align: left;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  margin: 15px 0;
  span {
    margin-left: 8px;
  }
  img {
    width: 18px;
    height: 18px;
    margin-left: 14px;
  }
}
.social-btn-fb {
  color: #fff;
  background-color: #3b5998;
  .container.button {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    button {
      padding: 4px 0 4px 46px;
      min-width: auto;
      width: 100%;
    }
  }
}
.social-btn-g {
  border: 1px solid #e1e8ee;
  color: #5d6974;
  margin-top: 0;
  cursor: pointer;
  div {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
